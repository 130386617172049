
import $ from 'jquery';
import util from 'utility';
import throttle from './global/throttle';
import animate from './global/animate';
import SlideInMenu from './objects/SlideInMenu';
import scrollTo from './objects/scrollTo';


const selectors = {
	siteHeader: '.m-site-header',
	overlay: '.m-site-header__overlay',
	leftMenuText: '.m-site-header__button--left .m-menu-button__label',
	wholePage: '.l-whole-page',
	logoContainer: '.m-logo-container'
}

const classNames = {
	leftOpen: 'm-site-header--left-open',
	rightOpen: 'm-site-header--right-open',
	submenuOpen: 'm-site-header--submenu-open',
	overlayOpen: 'm-site-header__overlay--open',
	htmlOpen: 'm-site-header--open'
}


let once = false;

let $elem = null,
	$overlay = null,
	$html = null,
	$wholePage = null,
	$logoContainer = null,
	leftMenu = null,
	$leftMenuText = null,
	rightMenu = null,
	submenu = null;


function initMenus() {

	$elem = $(selectors.siteHeader);
	$overlay = $(selectors.overlay);
	$html = $('html');
	$wholePage = $(selectors.wholePage);
	$logoContainer = $(selectors.logoContainer);
	$leftMenuText = $elem.find(selectors.leftMenuText);

	leftMenu = new SlideInMenu(
		'left',
		'.js-left-menu', '.js-left-menu-open', '.js-left-menu-close', '.js-left-menu-toggle', 'is-open'
	);

	rightMenu = new SlideInMenu(
		'right',
		'.js-right-menu', '.js-right-menu-open', '.js-right-menu-close', '.js-right-menu-toggle', 'is-open'
	);

	submenu = new SlideInMenu(
		'submenu',
		'.js-submenu', '.js-submenu-open', '.js-submenu-close', '.js-submenu-toggle', 'is-open'
	);

	// setup events

	leftMenu.init();
	rightMenu.init();
	submenu.init();
	leftMenu.setCallback(menuCallback);
	rightMenu.setCallback(menuCallback);
	submenu.setCallback(menuCallback);

	$overlay.on('click', function () {
		leftMenu.isOpen && leftMenu.closeMenu();
		rightMenu.isOpen && rightMenu.closeMenu();
		submenu.isOpen && submenu.closeMenu();
	})
}


function showOverlay() {
	$overlay.css('pointer-events', '');
	animate.show($overlay, classNames.overlayOpen);
	$html.addClass(classNames.htmlOpen);
}


function hideOverlay() {
	$overlay.css('pointer-events', 'none');
	animate.hide($overlay, classNames.overlayOpen);
	$html.removeClass(classNames.htmlOpen);
}


let rightMenuOpened = false;


function menuCallback(state) {

	// if the cookie banner is shown, there's a jump after the menu opens, and focuses on the menu element
	// scroll to the l-whole-page element smoothly before this happens to make transition a lot smoother

	if (state === SlideInMenu.BEFORE_OPEN) {
		const wholePageTop = $wholePage.offset().top;

		// logo container is height 0 on hero pages, and natural height on standard inner pages
		// wholePageTop - logoContainerHeight is the top of the page (allowing for cookie banner)
		const logoContainerHeight = $logoContainer.height();

		const currentScroll = document.body.scrollTop || document.documentElement.scrollTop;
		if (currentScroll < wholePageTop - logoContainerHeight) {
			scrollTo.scrollToPosition(wholePageTop - logoContainerHeight, 0.4);
		}
	}

	// show/hide overlay
	if (state === SlideInMenu.BEFORE_OPEN) showOverlay();
	if (state === SlideInMenu.BEFORE_CLOSE && this.name !== 'submenu') hideOverlay();

	// add/remove open classes
	if (state === SlideInMenu.BEFORE_OPEN) {
		(this.name === 'left') && $elem.addClass(classNames.leftOpen);
		(this.name === 'submenu') && $elem.addClass(classNames.submenuOpen);
		(this.name === 'right') && $elem.addClass(classNames.rightOpen);

		if (this.name === 'right' && !rightMenuOpened) {
			rightMenuOpened = true;

			// require sidebar view
			require(['app/search/sidebarView'], function (sidebarView) {
				sidebarView && sidebarView.initInstance && sidebarView.initInstance();
			});

		}
	}

	// remove state before close for left and submenus
	if (state === SlideInMenu.BEFORE_CLOSE) {
		(this.name === 'left') && $elem.removeClass(classNames.leftOpen);
		(this.name === 'submenu') && $elem.removeClass(classNames.submenuOpen);
	}

	// remove class after for right meun
	if (
		(this.name === 'right') &&
		state === SlideInMenu.AFTER_CLOSE
	) {
		(this.name === 'right') && $elem.removeClass(classNames.rightOpen);
	}

	// chamge text for left menu button
	if (this.name === 'left' && state === SlideInMenu.BEFORE_OPEN) {
		$leftMenuText.html('Close');
	}
	if (this.name === 'left' && state === SlideInMenu.BEFORE_CLOSE) {
		$leftMenuText.html('Menu');
	}
}


function initHeaderLightDark(siteHeader) {

	const dataAttr = 'data-header-light';
	const selectors = {
		module: `[${dataAttr}]`,
		button: '.js-left-menu-toggle'
	}

	const classNames = {
		darkClass: 't-header-light'
	}

	const menuButton = siteHeader.querySelector(selectors.button);
	let elems = [...document.querySelectorAll(selectors.module)];

	function getIntersectingElems() {
		if (menuButton) { // due to a series of excellent decisions, the menu button is now occasionally hidden. This means we have to check it exists...
			const menuButtonRect = menuButton.getBoundingClientRect();

			// makes cut-off point much smaller 
			// +/- 1 on top/bottom to resolve rounding errors

			const menuButtonCenterRect = {
				top: menuButtonRect.top + (menuButtonRect.height / 2) - 1,
				right: menuButtonRect.right,
				bottom: menuButtonRect.bottom - (menuButtonRect.height / 2) + 1,
				left: menuButtonRect.left,
			}

			return elems.filter(function (elem) {
				const rect = elem.getBoundingClientRect();
				const isIntersecting = (
					rect.top + rect.height > menuButtonCenterRect.top
					&& rect.left + rect.width > menuButtonCenterRect.left
					&& rect.bottom - rect.height < menuButtonCenterRect.bottom
					&& rect.right - rect.width < menuButtonCenterRect.right
				);
				return isIntersecting;
			});
		}
	}

	function siteHeaderClass() {
		if (getIntersectingElems()) { // see getIntersectingElems
			const elems = getIntersectingElems();
			elems.length && siteHeader.classList.add(classNames.darkClass);
			!elems.length && siteHeader.classList.remove(classNames.darkClass);
		}
	}

	siteHeaderClass();
	document.addEventListener('scroll', throttle(siteHeaderClass, 50));
}


// export

export const initInstance = function (el) {
	!once && initMenus();
	!once && initHeaderLightDark(el);
	once = true;
}
